button, .rwx-button {
  font-size:18px !important;
  line-height:18px !important;
  font-weight: normal !important;
}

a.no-decoration {
  @include onAccessible(false) {
    box-shadow: 0px 0px 5px 0px $blue;
    border:none;
    outline:none;
  }
}

.small-font {
  font-size:12px !important;
  line-height:24px !important;
  letter-spacing: 2.64px !important;
}

.case-study .break-out {
  @include snap-up-from('md') {
    margin-top:-120px;
    margin-bottom:80px;
    overflow:hidden;
  }
}

.extra-large-font {
  font-size:110px !important;
  line-height:122px !important;
  letter-spacing: 0px !important;  
  @include snap-down-from('md') {
  font-size:80px !important;
  line-height:92px !important;    
  }
}

video {
  width:100%;
}

figure {
  margin:0px;
}