header.header {
  min-height: 120px;
  ul {
    justify-content:center;
    li {
      &:before {
        display: none;
      }
      a {
        @include change-link-color($white);
      }
    }
  }
  .logo {
    width: 60px;
  }
  nav {
    @include snap-down-from('md') {
      display: flex;
      flex-direction: column;
      justify-content:center;
      transform: translateY(-100%);
      transition: 0.5s transform $transition-smooth;
      height:100%;
      width:100%;
      background: $blue;
      @include full-size;
      position: fixed !important;
      z-index:9999;
      &.open {
        transform:translateY(0);
        transition: 0.5s transform $transition-smooth;
      }
      ul {
        text-align: center;
        li {
          width:100%;
          margin-bottom:32px;
          a {
            font-size: rem($h3-size);
            line-height: rem($h3-size) + rem($headingincrement);
          }
        }
      }
      .menu-close {
        position: absolute;
        top:24px;
        right:24px;
      }
    }
  }
}