.icon-button {
  &.lg {
    width: 72px;
    height:72px;
    border-radius: 36px;
  }
  &.sm {
    width:24px;
    height:24px;
    border-radius: 12px
  }
  flex-shrink: 0;
  width:48px;
  height:48px;
  border-radius: 24px;
  transition: all 0.2s $transition-base;
  @include flex-center;
  @include onAccessible(true) {
    transform: translateY(-4px);
    transition: all 0.3s $transition-base;
  }
}