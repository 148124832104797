.slider-container {
  background-color:white;
  box-shadow: -20px 23px 86px #0583F226;
  padding: 40px 80px;
  border-radius:24px;
  position: relative;
  .slider-control {
    position: absolute;
    top:50%;
    &:first-of-type {
      left:0px;
      transform: translate(-50%, -50%);
      &:hover, &:focus, &:active {
        transform: translate(-55%, -50%);
        box-shadow: 1px 0px 8px 1px black;
      }
    }
    &:last-of-type {
      right:0px;
      transform: translate(50%, -50%);
      &:hover, &:focus, &:active {
        transform: translate(55%, -50%);
        box-shadow: 1px 0px 8px 1px black;
      }
    }
  }
}