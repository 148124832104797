@import '../global.scss';
@import '../components/news-item.scss';
@import '../components/linkedin-post.scss';
@import '../components/slider.scss';
@import '../components/contact-form.scss';

.hero {
  background-image: url('/assets/test.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center;
  background-attachment: fixed;
  height: 80vh;
  display: flex;
  flex-direction:column;
  justify-content:center;
}