footer.footer {
  .logo, .image-scale {
    width:50%;
  }
  .image-scale {

  }
  .bottom-bar {
    height:24px;
  }
}