.contact-form .rwx-form-item {
  input, textarea {
    border-radius: 8px;
    background-color:white;
    color: #660583F2;
    &::placeholder {
      color: $light-blue;
      text-transform: uppercase;
    }
  }
}

.contact-form button {
  background-color: white !important;
  color: $light-blue !important;
}