$blue: #010A26;
$light-blue: #0583F2;
$turquoise: #CAE5F5;
$grey: #1A0A261A;
$silver: #A8A8A8;

$colors: (
  'mid-blue': #0C3281,
  'lighter-blue': #0583F21A
);

$spacings: (
  'xxl': 56px,
  'xxxl': 64px
);

$font-size-small: 14px;

$button-padding: 40px;

$font-base: "neue-haas-grotesk-text",sans-serif;
$font-heading: "neue-haas-grotesk-text",sans-serif;