.news-item {
  height:100%;
  display: flex;
  flex-direction:column;
  justify-content:space-between;
  position: relative;
  &.full-width {
    @include snap-up-from('md') {
     flex-direction:row-reverse; 
    }
  }
}